import { useState } from 'react'
import { ObjectSchema } from 'yup'
import { objectFormData, setTypeForm, setTypeDataForm } from 'helpers/form'

type TUseForm = {
    schema?: ObjectSchema<any>
    request: (data: any) => void
}

const formatFormData = (data: any, rules: any) => setTypeDataForm(data, rules)

const formatData = (data: any, rules: any) => rules ? setTypeForm(data, rules) : data

export const useForm = ({ schema, request }: TUseForm) => {
    const [errors, setErrors] = useState<any>({})
    const submit = (formData: any, rules: any = null, setFormData: boolean = false) => {
        const data = objectFormData(formData)
        if (schema) {
            schema.validate(data, { abortEarly: false })
                .then(() => {
                    setErrors({})
                    request(setFormData ? formatFormData(formData, rules) : formatData(data, rules))
                })
                .catch((err) => {
                    const errors = err.errors.reduce((p: any, c: any) => ({ ...p, [c.key]: c.value }), {})
                    setErrors(errors)
                })
        } else {
            setErrors([])

            request(setFormData ? formatFormData(formData, rules) : formatData(data, rules))
        }
    }

    return { errors, submit }
}
