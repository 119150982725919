import NewspaperIcon from '@mui/icons-material/Newspaper';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';

// import { props } from './props';
// const { name } = props;

const menuList: TMenu[] = [
	{
		id: 'News',
		label: 'Новости Foton',
		icon: () => <NewspaperIcon />,
		path: `https://portal.foton-motor.ru/`,
		parent: 'other',
		roles: ['distributor'],
	},
	{
		id: 'News Foton',
		label: 'Новости Portal',
		icon: () => <MenuIcon />,
		path: `https://portal.foton-motor.ru/`,
		parent: 'other',
		roles: ['distributor'],
	},
	{
		id: 'LK',
		label: 'Личный кабинет',
		icon: () => <PersonIcon />,
		path: `https://portal.foton-motor.ru/`,
		parent: 'other',
		roles: ['distributor'],
	},
];

export default menuList;
