import { Link } from 'react-router-dom'
import LinkUi from '@mui/material/Link'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

export const Item: React.FC<TChildMenu & {is_section?: boolean}> = ({ path, icon, label, is_section }) => {
    return (
        <LinkUi
            underline="none"
            color="inherit"
            to={path}
            component={Link}
        >
            <ListItemButton>
                <ListItemIcon >
                    {icon()}
                </ListItemIcon>
                <ListItemText disableTypography sx={{fontSize: '0.7em'}} primary={label} />
            </ListItemButton>
        </LinkUi>
    )
}
