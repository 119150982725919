import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from "react-router-dom"
import { Layout } from "modules/Layout"
import { AuthProvider } from 'modules/Auth'
import reportWebVitals from './reportWebVitals'

import 'config'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(
  <AuthProvider>
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  </AuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
