import { SnackbarProvider, SnackbarOrigin } from 'notistack'

const maxSnack = 3 

const anchorOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'right',
}

export const Notification: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <SnackbarProvider maxSnack={maxSnack} anchorOrigin={anchorOrigin} >
            {children}
        </SnackbarProvider>
    )
}