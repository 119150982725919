import axios from 'axios'
import useSWR from 'swr'
import { useParams, useSearchParams } from 'react-router-dom'
import { getRoute, TRoutes } from 'helpers/routes'

const fetcherItem = (url: string, up: string) => axios.get(url, { params: { up } }).then(res => res.data)

export const useGetItem = (url: keyof TRoutes) => {
    const { id } = useParams()
    const [searchParams] = useSearchParams()
    const { data, error, mutate } = useSWR([getRoute(url, id!), searchParams.get('up')], fetcherItem)

    return { data, error, id, mutate }
}