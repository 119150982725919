// import React from 'react'

import { Navigate } from 'react-router-dom'
// import type { RouteObject } from 'react-router-dom'

// import { Loader } from 'components/Loader'

// const Dashboard = React.lazy(() => import("views/dashboard/page/Dashboard"))

const routes: any[] = [
    // {
    //     path: "/",
    //     element: <React.Suspense fallback={<Loader />}>
    //         <Dashboard />
    //     </React.Suspense>
    // }
    {
        path: `/`,
        element: <Navigate to="/" />,
        roles: ['distributor', 'dealer']
    },
]

export default routes
