import { useState, useEffect } from 'react'
import axios from 'axios'

import { AuthContext } from './context'
import { getRoute } from 'helpers/routes'
import { getToken, setToken, removeToken } from 'helpers/localStorage'

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let [user, setUserState] = useState<any>(null)
    let [check, setCheck] = useState<boolean>(false)
    let [token, setTokenState] = useState<string | null>(null)

    const addToken = (token: string) => {
        setToken(token)
        setTokenState(token)
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }

    useEffect(() => {
        const token = getToken()
        if (token) {
            addToken(token)
        }
        setCheck(true)
    }, []) //eslint-disable-line

    const signIn = (data: any) => {
        return axios.post(getRoute('api.login'), data)
            .then(data => {
                addToken(data.data.token.access_token)
                setCheck(true)
            }).catch((e) => console.log(e))
           
    }

    const setUser = (data: any) => {
        setUserState(data)
    }

    const signOut = () => {
        return axios.post(getRoute('api.logout'))
            .finally(() => {
                setUserState(null)
                removeToken()
                setTokenState(null)
                axios.defaults.headers.common['Authorization'] = ''
            })
    }

    const value = { user, signIn, signOut, check, token, setUser }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>

}
