import ContactsIcon from '@mui/icons-material/Contacts';
import GroupIcon from '@mui/icons-material/Group';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import BookIcon from '@mui/icons-material/Book';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import { props } from './props';
const { name } = props;
const menuList: TMenu[] = [
	{
		id: 'contracts',
		label: 'Сервисные контракты',
		icon: () => <ContactsIcon />,
		path: `https://portal.foton-motor.ru/`,
		parent: 'client',
		roles: ['distributor'],
	},
	{
		id: 'clients',
		label: 'Клиенты',
		icon: () => <GroupIcon />,
		path: `https://portal.foton-motor.ru/`,
		parent: 'client',
		roles: ['distributor'],
	},
	{
		id: 'auto',
		label: 'Автомобили клиентов',
		icon: () => <DirectionsCarIcon />,
		path: `https://portal.foton-motor.ru/`,
		parent: 'client',
		roles: ['distributor'],
	},
	{
		id: 'garant',
		label: 'Гарантийная регистрация',
		icon: () => <AppRegistrationIcon />,
		path: `/distributor/${name}`,
		parent: 'client',
		roles: ['distributor'],
	},
	{
		id: 'glossary',
		label: 'Справочники',
		icon: () => <BookIcon />,
		path: `https://portal.foton-motor.ru/`,
		parent: 'client',
		roles: ['distributor'],
	},
];

export default menuList;
