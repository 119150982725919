import { createContext } from 'react'

interface AuthContextType {
    check: boolean
    user: any
    token: string | null
    signIn: (user: any) => Promise<any>
    signOut: () => void
    setUser: (data: any) => void
}

export const AuthContext = createContext<AuthContextType>(null!)