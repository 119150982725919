import { useSearchParams } from 'react-router-dom'

const key = 'tab'

export const useTabs = (main: string = 'main') => {
    const [searchParams, setSearchParams] = useSearchParams()
    const tab = searchParams.get(key) ?? main

    const handleChangeTab = (_: React.SyntheticEvent, value: string) => {
        setSearchParams({ [key]: value })
    }

    return { tab, handleChangeTab }
}