const number = (item: any) => {
    return (!item || item === 'null') ? null : +item
}

const array = (item: any) => {
    return item ? item.split('|') : 'null'
}

const arrayNumber = (item: any) => {
    return item ? item.split('|').map((item: string) => +item) : 'null'
}

const boolean = (item: any) => {
    return item ? 1 : 0
}

const format = (item: any, rules: string) => {
    switch (rules) {
        case "number":
            return number(item)
        case "array":
            return array(item)
        case "boolean":
            return boolean(item)
        case "arrayNumber":
            return arrayNumber(item)
        default:
            return item
    }
}

export const setTypeDataForm = (data: any, rules: any) => {
    for (let field in rules) {
        const item = data.get(field)
        if (item !== undefined || rules[field] === "boolean") {
            data.set(field, format(item, rules[field]))
        }
    }
    return data
}

export const setTypeForm = (data: any, rules: any) => {
    return Object.keys(rules).reduce((p, c) => (data[c] !== undefined || rules[c] === "boolean")
        ? { ...p, [c]: format(data[c], rules[c]) }
        : p, data)
}
