import React from 'react'
// import type { RouteObject } from 'react-router-dom'

import { Loader } from 'components/Loader'

const Dashboard = React.lazy(() => import("views/login/page/Login"))

const routes: any[] = [
    {
        path: "/login",
        element: (<React.Suspense fallback={<Loader />}>
            <Dashboard />
        </React.Suspense>),

    }
]

export default routes
