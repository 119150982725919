// import DashboardIcon from '@mui/icons-material/Dashboard'

const menuList: TMenu[] = [
	// {
	//     id: 'dashboard',
	//     label: 'Главная',
	//     icon: () => <DashboardIcon />,
	//     path: '/'
	// },
	{
		id: 'main',
		label: '',
		is_parent: true,
    roles: ['distributor', 'dealer']
	},
	{
		id: 'client',
		label: '',
		is_parent: true,
    roles: ['distributor', 'dealer']

	},
	{
		id: 'other',
		label: '',
		is_parent: true,
    roles: ['distributor', 'dealer']

	},
];

export default menuList;
