import React from 'react';
// import { Navigate } from 'react-router-dom';
// import type { RouteObject } from 'react-router-dom';

import { Loader } from 'components/Loader';
import { props } from './props';

const { name } = props;
const List = React.lazy(() => import(`views/dealer/${name}/page/List`));
const Show = React.lazy(() => import(`views/dealer/${name}/page/Show`));
const Garant = React.lazy(() => import(`views/dealer/warranty/page/Garant`));

const routes: any[] = [
	{
		path: `/dealer/warranty`,
		element: (
			<React.Suspense fallback={<Loader />}>
				<Garant />
			</React.Suspense>
		),
    roles: ['dealer']

	},
	{
		path: `/dealer/${name}/:page`,
		element: (
			<React.Suspense fallback={<Loader />}>
				<List />
			</React.Suspense>
		),
    roles: ['dealer']
	},
	{
		path: `/dealer/${name}/show/:id`,
		element: (
			<React.Suspense fallback={<Loader />}>
				<Show />
			</React.Suspense>
		),
    roles: ['dealer']
	},
];

export default routes;
