const key = 'color_mode'
const list = ['light', 'dark']

export const getColorMode = (): ('light' | 'dark') => {
    const store = localStorage.getItem(key)

    return (store && list.includes(store)) ? store as ('light' | 'dark') : 'light'
}

export const setColorMode = (mode: 'light' | 'dark') => {
    localStorage.setItem(key, mode)
} 