import CloudIcon from '@mui/icons-material/Cloud';
import { props } from './props';
const { name } = props;

const menuList: TMenu[] = [
	{
		id: name,
		label: 'Файловое хранилище',
		icon: () => <CloudIcon />,
		path: `https://portal.foton-motor.ru/`,
		parent: 'main',
    roles: ['dealer']

	},
];

export default menuList;
