
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ConfigContext } from './context'
import { ru } from 'date-fns/locale'

const config = {
    title: "FOTON"
}

export const Config: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <ConfigContext.Provider value={config}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
                {children}
            </LocalizationProvider>
        </ConfigContext.Provider>
    )
}
