import CloudIcon from '@mui/icons-material/Cloud';
import GroupIcon from '@mui/icons-material/Group';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ArchiveIcon from '@mui/icons-material/Archive';
import { props } from './props';
const { name } = props;

const menuList: TMenu[] = [
	{
		id: name,
		label: 'Файловое хранилище',
		icon: () => <CloudIcon />,
		path: `https://portal.foton-motor.ru/`,
		parent: 'main',
		roles: ['distributor'],

	},
	{
		id: name,
		label: 'Пользователи',
		parent: 'main',
		icon: () => <GroupIcon />,
		path: 'https://portal.foton-motor.ru/',
		roles: ['distributor'],

	},
	{
		id: name,
		label: 'Дилеры',
		parent: 'main',
		icon: () => <DirectionsCarIcon />,
		path: 'https://portal.foton-motor.ru/',
		roles: ['distributor'],

	},
	{
		id: name,
		label: 'Архивные дилеры',
		parent: 'main',
		icon: () => <ArchiveIcon />,
		path: 'https://portal.foton-motor.ru/',
		roles: ['distributor'],

	},
];

export default menuList;
