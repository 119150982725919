//genered
import type { RouteObject } from 'react-router-dom'
import dashboardRoutes from '../views/dashboard/routes'
import loginRoutes from '../views/login/routes'
import dealerOtherRoutes from '../views/dealer/other/routes'
import dealerMainRoutes from '../views/dealer/main/routes'
import dealerGarantyRoutes from '../views/dealer/warranty/routes'
import distributorOtherRoutes from '../views/distributor/other/routes'
import distributorMainRoutes from '../views/distributor/main/routes'
import distributorGarantyRoutes from '../views/distributor/warranty/routes'

const routes: RouteObject[] = [
...dashboardRoutes, ...loginRoutes, ...dealerOtherRoutes, ...dealerMainRoutes, ...dealerGarantyRoutes, ...distributorOtherRoutes, ...distributorMainRoutes, ...distributorGarantyRoutes
]

export default routes
