import axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'
import { getRoute } from 'helpers/routes'

import { useAuth } from 'helpers/hooks'

export const useGetProfile = () => {
  const { setUser, signOut } = useAuth()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    axios.get(getRoute('api.profile'))
      .then(data => {
        setUser(data)
      })
      .catch(() => {
        signOut()
      })
      .finally(() => {
        setLoading(false)
      })
  }, []) //eslint-disable-line

  return { loading }
}
