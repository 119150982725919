import Box from '@mui/material/Box'
import LinkUi from '@mui/material/Link'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'

export const NotFound: React.FC = () => {
    return (
        <Box>
            <Typography variant="h1" component="h2">
                404
            </Typography>
            <LinkUi component={Link} to="/">На главную</LinkUi>
        </Box>
    )
}