
import { useState, useMemo } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'

import { ColorModeContext } from './components/ColorMode'
import { Router } from './components/Router'
import { NavBar } from './components/NavBar'
import { getColorMode, setColorMode } from 'helpers/localStorage'
import { Loader } from 'components/Loader'
import { useAuth } from 'helpers/hooks'
import { GetProfile } from './components/GetProfile'
import { Notification } from 'modules/Notification'
import { Config } from 'modules/Config'

export const Layout: React.FC = () => {

    const { user, check, token } = useAuth()
    const location = useLocation()
    const [mode, setMode] = useState<'light' | 'dark'>(getColorMode())
    const navigate = useNavigate();
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => {
                    const mode = prevMode === 'light' ? 'dark' : 'light'
                    setColorMode(mode)
                    return mode
                })
            },
        }), [])

    const theme = useMemo(() => createTheme({ palette: { mode } }), [mode])

    if (!check) return <Loader />

    if (token && !user) return <GetProfile />

    if (!user && !location.pathname.includes('login')) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    if (user && location.pathname.includes('login')) {
        navigate(`${user.data.data.role}/warranty`)
    }

    return (
        <Config>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <Notification>
                        <Box sx={{ display: 'flex' }}>
                            {user && <NavBar />}
                            <Box
                                component="main"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'light'
                                            ? theme.palette.grey[100]
                                            : theme.palette.grey[900],
                                    flexGrow: 1,
                                    height: '100vh',
                                    overflow: 'auto',
                                }}
                            >
                                <Toolbar />
                                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                                    <Router />
                                </Container>
                            </Box>
                        </Box>
                    </Notification>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </Config>
    )
}
