// import React from 'react'
// import { Navigate } from 'react-router-dom'
import type { RouteObject } from 'react-router-dom'
// import { props } from './props'

// import { Loader } from 'components/Loader'

// const { name } = props


const routes: RouteObject[] = [
  
]

export default routes
