//genered
import dashboardMenu from '../views/dashboard/menu';
import dealerOtherMenu from '../views/dealer/other/menu';
import dealerMainMenu from '../views/dealer/main/menu';
import dealerGarantyMenu from '../views/dealer/warranty/menu';
import distributorOtherMenu from '../views/distributor/other/menu'
import distributorMainMenu from '../views/distributor/main/menu'
import distributorGarantyMenu from '../views/distributor/warranty/menu'

const menuList: TMenu[] = [
	...dashboardMenu,
	...dealerOtherMenu,
	...dealerMainMenu,
	...dealerGarantyMenu,
  ...distributorOtherMenu,
  ...distributorMainMenu,
  ...distributorGarantyMenu
];

export default menuList;
