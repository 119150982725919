// import React from 'react'
// import { Navigate } from 'react-router-dom'
import type { RouteObject } from 'react-router-dom'

// import { Loader } from 'components/Loader'
// import { props } from './props'

// const { name } = props


const routes: RouteObject[] = [

]

export default routes
