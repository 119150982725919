import { useRoutes } from "react-router-dom"
import baseRoutes from "generated/routes"
import { NotFound } from './NotFound'
// import { useAuth } from "helpers/hooks"

const routes = [...baseRoutes, { path: "*", element: <NotFound />},]

export const Router: React.FC = () => {
  // const { user } = useAuth()
    // const routesByRole = user && routes.filter((r:any) => r.roles.includes(user.data.data.role))
    // console.log(routesByRole)
    const element = useRoutes(routes)
  
    return (
        <div>
            {element}
        </div>
    )
}
