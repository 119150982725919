import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListSubheader from '@mui/material/ListSubheader';
import LogoutIcon from '@mui/icons-material/Logout';
import LogoDark from 'static/LogoDark.png';
import LogoWhite from 'static/LogoWhite.png';
import menuList from 'generated/menu';
import { ColorMode } from '../ColorMode';
import { Item } from './Item';
import { useAuth } from 'helpers/hooks';
import { getColorMode } from 'helpers/localStorage/colorMode';
import { Link } from 'react-router-dom';

const drawerWidth: number = 240;
interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

export const NavBar: React.FC = () => {
	const { signOut, user } = useAuth();

	const menuListByRole = menuList.filter((r: any) =>
		r.roles.includes(user.data.data.role)
	);

	const menuNotSection = menuListByRole.filter(
		(item) => !('is_parent' in item) && !item.parent
	) as TChildMenu[];
	const menuSection = menuListByRole.filter(
		(item) => 'is_parent' in item
	) as TParentMenu[];
	const getMenuChild = (section: string) =>
		(
			menuListByRole.filter(
				(item) => !('is_parent' in item) && item.parent === section
			) as TChildMenu[]
		).sort((a, b) => (a.sort ?? 500) - (b.sort ?? 500));
	const [open, setOpen] = useState(true);

	const toggleDrawer = () => {
		setOpen(!open);
	};

	const Logo = getColorMode() === 'dark' ? LogoWhite : LogoDark;

	return (
		<Box sx={{ maxHeight: '100vh', display: 'flex' }}>
			<CssBaseline />
			<AppBar position='absolute' open={open}>
				<Toolbar
					sx={{
						pr: '24px',
					}}
				>
					<IconButton
						edge='start'
						color='inherit'
						aria-label='open drawer'
						onClick={toggleDrawer}
						sx={{
							marginRight: '36px',
							...(open && { display: 'none' }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<Typography
						component='h1'
						variant='h6'
						color='inherit'
						noWrap
						sx={{ flexGrow: 1 }}
					>
						{user?.data?.data?.role}
					</Typography>
					<ColorMode />
					<IconButton sx={{ ml: 1 }} color='inherit' onClick={signOut}>
						<LogoutIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Drawer variant='permanent' open={open}>
				<Toolbar
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						px: [1],
					}}
				>
					<Link to='/'>
						<img src={Logo} alt='logo' width={150} height={42} />
					</Link>
					<IconButton onClick={toggleDrawer}>
						<ChevronLeftIcon />
					</IconButton>
				</Toolbar>
				<Divider />
				<List component={'nav'}>
					{menuNotSection.map((item) => (
						<Item key={item.id} {...item} />
					))}
					{menuSection.map((item) => (
						<div
							style={{ borderBottom: '1px solid grey', marginBottom: '10px' }}
							key={item.id}
						>
							{open && (
								<ListSubheader component={'div'}>{item.label}</ListSubheader>
							)}
							{getMenuChild(item.id).map((child) => (
								<Item key={child.id} is_section {...child} />
							))}
						</div>
					))}
				</List>
			</Drawer>
		</Box>
	);
};
