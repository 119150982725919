import { useState } from 'react'

export const useFormErr = (type: 'string' | 'object' = 'string') => {
    const [err, setErr] = useState<string[]>([])

    const addErr = (err: any) => {
        if (type === 'string') {
            setErr([err])
        }
    }

    const resetErr = () => {
        setErr([])
    }

    return { err, addErr, resetErr }
}